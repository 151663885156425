<template>
	<aside>
		<h2>Patient</h2>
		<div class="error">
			<p class="has-error" v-if="$v.patientData.$anyError">
				<strong>Bitte alle Pflichtfelder (*) ausfüllen</strong>
			</p>
		</div>
		<div class="patient-info-fields">
			<div class="input-field">
				<div class="inputs">
					<label for="vorname">Vorname</label>
					<input
						type="text"
						v-model="$v.patientData.vorname.$model"
						name="vorname"
						id="vorname"
					/>
				</div>
			</div>
			<div class="input-field">
				<div class="radiobuttons">
					<p>Geschlecht</p>
					<input
						type="radio"
						name="geschlecht"
						id="maennlich"
						value="m"
						v-model="$v.patientData.geschlecht.$model"
					/>
					<label class="radiobutton" for="maennlich">Männlich</label>
					<input
						type="radio"
						name="geschlecht"
						id="weiblich"
						value="w"
						v-model="$v.patientData.geschlecht.$model"
					/>
					<label class="radiobutton" for="weiblich">Weiblich</label>
					<input
						type="radio"
						name="geschlecht"
						id="divers"
						value="d"
						v-model="$v.patientData.geschlecht.$model"
					/>
					<label class="radiobutton" for="divers">Divers</label>
				</div>
			</div>
			<div class="input-field">
				<div class="inputs">
					<label for="nachname"
						>Nachname <br />
						Patienten-ID*<br /><span
							class="has-error"
							v-if="$v.patientData.nachname.$error"
							>Darf nicht leer sein!</span
						></label
					><input
						id="nachname"
						type="text"
						name="nachname"
						v-model="$v.patientData.nachname.$model"
						autocomplete="off"
					/>
				</div>
			</div>
			<div class="input-field">
				<div class="radiobuttons">
					<p>Versicherung</p>
					<input
						type="radio"
						name="versicherung"
						id="gesetzlich"
						value="Gesetzlich"
						v-model="$v.patientData.versicherung.$model"
					/>
					<label class="radiobutton" for="gesetzlich">Gesetzlich</label>
					<input
						type="radio"
						name="versicherung"
						id="privat"
						value="Privat"
						v-model="$v.patientData.versicherung.$model"
					/>
					<label class="radiobutton" for="privat">Privat</label>
				</div>
			</div>
			<div class="input-field">
				<div class="inputs">
					<label for="age"
						>Alter<br /><span
							class="has-error"
							v-if="$v.patientData.alter.$error"
						>
							Muss größer als 0 sein!</span
						></label
					><input
						id="age"
						type="number"
						name="alter"
						v-model="$v.patientData.alter.$model"
						min="0"
						autocomplete="off"
					/>
				</div>
			</div>
			<div class="input-field">
				<div class="radiobuttons">
					<p>Versorgung</p>
					<input
						type="radio"
						name="versorgung"
						id="regelversorgung"
						value="Regelversorgung"
						v-model="$v.patientData.versorgung.$model"
					/>
					<label class="radiobutton" for="regelversorgung"
						>Regelversorgung</label
					>
					<input
						type="radio"
						name="versorgung"
						id="gleichartig"
						value="Gleichartig"
						v-model="$v.patientData.versorgung.$model"
					/>
					<label class="radiobutton" for="gleichartig">Gleichartig</label>
					<input
						type="radio"
						name="versorgung"
						id="andersartig"
						value="Andersartig"
						v-model="$v.patientData.versorgung.$model"
					/>
					<label class="radiobutton" for="andersartig">Andersartig</label>
				</div>
			</div>
		</div>
	</aside>
</template>

<script>
import { required, minValue } from 'vuelidate/lib/validators';
export default {
	name: 'patientData',
	data() {
		return {
			patientData: {
				vorname: '',
				nachname: '',
				alter: '',
				geschlecht: '',
				versicherung: '',
				versorgung: ''
			}
		};
	},
	watch: {
		patientData: {
			deep: true,
			handler() {
				this.checkForErrors();
			}
		}
	},
	validations: {
		patientData: {
			vorname: {},
			nachname: {
				required
			},
			alter: {
				minValue: minValue(1)
			},
			geschlecht: {},
			versicherung: {},
			versorgung: {}
		}
	},
	methods: {
		checkForErrors() {
			this.$v.patientData.$touch();
			if (!this.$v.$anyError) {
				return this.patientData;
			} else {
				return false;
			}
		},
		reset() {
			this.patientData = {
				vorname: '',
				nachname: '',
				alter: '',
				geschlecht: '',
				versicherung: '',
				versorgung: ''
			};
			this.$v.$reset();
		}
	}
};
</script>

<style lang="scss">
aside {
	h2 {
		padding: 1rem;
	}
	p.has-error {
		padding: 0 1rem;
		strong {
			color: $background-nok;
		}
	}
	.patient-info-fields {
		display: flex;
		flex-wrap: wrap;
		padding: 0 1rem;
		.input-field {
			justify-content: flex-start;
			align-items: center;
			width: 60%;
			flex-grow: 0;
			flex-shrink: 1;
			display: flex;
			margin-top: 0.33rem;
			&:nth-child(2n) {
				width: calc(40% - 1rem);
				margin-left: 1rem;
				justify-content: center;
			}
			.inputs {
				display: grid;
				grid-template-columns: 1fr 1fr;
				width: 100%;
				justify-content: center;
				align-items: center;
			}
			.radiobuttons {
				display: grid;
				grid-template-columns: 1fr 5fr;
				width: 100%;
				font-size: 0.75rem;
				p {
					grid-column: 1 / 3;
					font-size: 0.85rem;
					margin-bottom: 0.5rem;
				}
				input[type='radio'] {
					height: 0.75rem;
				}
			}
		}
	}
}
</style>
